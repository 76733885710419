.counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h1 {
        font-size: 1.2rem;
    }
    
    .counter {
        display: flex;
        align-items: center;

        h1 {
            font-size: 5.5rem;
            font-weight: 700;
            font-family: var(--styled-font);
            text-align: center;
            min-width: 3.2rem;
            background: #fff;
            color: #121809;
            padding: 0.75rem 1rem;
            border-radius: 3rem;
            box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.15);
            z-index: 5;
            cursor: default;
        }

        button {
            height: 2.4rem;
            aspect-ratio: 0.85;
            outline: none;
            border: none;
            background: #fff;
            box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.15);
            font-size: 1.2rem;
            font-weight: 600;
            cursor: pointer;
            opacity: 0.75;
            z-index: 0;
            transition: all 0.2s ease;

            &:nth-of-type(1) {
                border-radius: 0.5rem 0rem 0rem 0.5rem;
                transform: translateX(0.25rem);
            }
            &:nth-of-type(2) {
                border-radius: 0rem 0.5rem 0.5rem 0rem;
                transform: translateX(-0.25rem);
            }

            &:hover {
                transform: translateX(0);
            }
        }
    }

    .eth-cost {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        // opacity: 0.8;
        color: #fff;
        line-height: 1rem;

        // svg {
        //     font-size: 1rem;
        //     margin-bottom: 0.5rem;
        //     border: 2px solid #fff;
        //     border-radius: 5rem;
        //     padding: 0.25rem;
        // }
    }
}