@mixin stroke($color: #000, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color,
    #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color,
    0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

.wallet-login-screen {
  position: absolute;
  inset: 0 0;

  width: 100%;
  min-height: 100%;
  padding: 5rem;
  box-sizing: border-box;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .wallet-login-container {
    // width: min(45rem, 95vw);
    // aspect-ratio: 16 / 9;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    // background: var(--glass-bg);
    // border-radius: 1rem;
    // backdrop-filter: var(--glass-filter);
    // box-shadow: var(--glass-shadow);

    .wallet-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      svg {
        font-size: 8rem;
        animation: loading infinite 1.5s ease-out;
      }
      h1 {
        font-size: 2rem;
        text-align: center;
      }
    }

    .token-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      .token-card {
        width: 12rem;
        aspect-ratio: 1.4;

        padding: 1.25rem 1rem;
        box-sizing: border-box;

        background: hsla(88, 62%, 30%, 0.5);
        backdrop-filter: var(--glass-filter);
        box-shadow: var(--glass-shadow);
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        h2 {
          font-family: var(--styled-font);
          color: var(--logo-color);
          text-align: center;
          font-size: 1.2rem;
          font-weight: 700;
          height: 2.4rem;
          margin-bottom: 1.2rem;
          white-space: nowrap;
        }

        p {
          font-weight: 600;
        }
      }
    }

    .metamask-unavailable {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      text-align: center;

      svg {
        margin-top: 1.5rem;
        font-size: 8rem;
      }
      h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }

    .metamask-login {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      h1 {
        color: var(--text);
        font-size: 2rem;
      }

      .connect-button {
        background: transparent;
        outline: none;
        border: none;
      
        font-family: var(--styled-font);
        font-weight: 700;
        font-size: 3.4rem;
        line-height: 8rem;
        white-space: nowrap;
        background-image: linear-gradient(
          90deg,
          var(--logo-color) 0%,
          var(--logo-color) 49%,
          #fff 50%,
          #fff 100%
        );
        background-size: 200%;
        background-clip: text;
        background-position: right;
        color: transparent;
        letter-spacing: 2px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      
        position: relative;
      
        &:hover {
          background-position: left;
        }
      
        &::before {
          content: "CONNECT";
          position: absolute;
          inset: 0 0;
          @include stroke(#080904, 2px);
          z-index: -5 !important;
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .wallet-login-screen {
    padding: 2rem;
    justify-content: center;
    
    .wallet-login-container {
      flex-direction: row;
      // margin: 1rem auto;
      aspect-ratio: unset;
      // min-height: 70vh;
    }
  }
}
