.header-container {
    max-width: 100vw;
    height: 4rem;
    position: fixed;
    inset: 0 0;

    padding: 1rem 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 100;

    .logo {
        height: min(4.2rem, 7vh);
        aspect-ratio: 21 / 9;
        object-fit: contain;
    }

    .wallet-address {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        font-size: 1.1rem;
        font-weight: 600;
        cursor: default;

        background: hsl(102, 86%, 49%, 0.5);
        backdrop-filter: var(--glass-filter);
        box-shadow: var(--glass-shadow);
        color: hsl(0, 0%, 10%);

        border-radius: 0.75rem;
        position: relative;

        width: 12rem;
        height: 3rem;
        box-sizing: border-box;
        padding: 0rem 1.5rem;
    }

    .wallet-address::after {
        content: "";
        position: absolute;
        inset: 50% 1.25rem;
        transform: translateY(-50%);
        width: 10px;
        aspect-ratio: 1;
        border-radius: 1rem;
        background: #00ee00;
        backdrop-filter: var(--glass-filter);
        box-shadow: var(--glass-shadow);
    } 

    .connect-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;

        width: 12rem;
        height: 3rem;
        
        outline: none;
        border: none;
        border-radius: 0.75rem;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: var(--styled-font);
        background: hsl(102, 86%, 49%, 0.5);
        backdrop-filter: var(--glass-filter);
        box-shadow: var(--glass-shadow);
        color: hsl(0, 0%, 10%);

        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }

    .init {
        background: hsl(0, 0%, 100%, 0.5);

        cursor: progress;

        svg {
            font-size: 1.6rem;
            animation: loading 1.2s infinite ease-in-out;
        }
    }

    .unavailable {
        background: hsl(51, 94%, 55%, 0.5);

        font-size: 1.1rem;
        white-space: nowrap;

        cursor: not-allowed;

        svg {
            font-size: 1.6rem;
        }
    }
}

@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

// mobile media query
@media screen and (max-width: 768px) {
    .header-container {
        // padding: 1rem 0.5rem;

        .wallet-address {
            padding: 0rem 1rem;
            width: 11rem;
        }
    }
  }