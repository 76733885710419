.debug-box {
    position: fixed;
    z-index: 500;
    inset: auto 1rem 1rem;
    width: 25%;
    min-height: 5rem;
    height: fit-content;
    background-color: var(--glass-bg);
    backdrop-filter: var(--glass-filter);
    padding: 1rem;
    border-radius: 1rem;

    h1 {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
}