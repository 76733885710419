@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;600;800&display=swap');

:root {
  --styled-font: 'Saira', sans-serif;
  font-family: 'Saira', sans-serif;
  --text: #EEE;
  --text-alternate: #000;
  color: var(--text);
  --logo-color: #51ea11;

  --bg: hsl(0, 0%, 0%);
  --glass-bg: hsla(0, 0%, 0%, 0.5);
  --glass-filter: blur(20px) saturate(1.2);
  --glass-border: hsla(0, 0%, 20%, 0);
  --glass-shadow: 0px 2px 12px 0px hsla(0, 0%, 0%, 0.35);
}

.Toastify__toast {
  background: var(--glass-bg) !important;
  backdrop-filter: var(--glass-filter);
  color: rgb(255, 255, 255) !important;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: 1.2rem;
  
  border-radius: 0.5rem !important;

  // .Toastify__toast-icon {
  //     display: none;
  // }

  .Toastify__close-button > svg {
    color: #FFF !important;
  }
}

.app {
  min-height: 100vh;
  background-size: 300%;
  background-position: right;
  position: relative;
  overflow: hidden;

  .bg-cover {
    height: 100vh;
    width: 100%;
    inset: 0 0;
    z-index: -10;

    position: absolute;
    background-image: url(./assets/images/bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
