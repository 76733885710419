.loader-container {
    width: min(25rem, 90vw);
    aspect-ratio: 12 / 9;
    // margin: 5rem auto;  

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    background: var(--glass-bg);
    border-radius: 1rem;
    backdrop-filter: var(--glass-filter);
    box-shadow: var(--glass-shadow);

    svg {
        font-size: 8rem;
        animation: loading infinite 1.5s ease-out;
    }
    h1 {
        font-size: 2rem;
        text-align: center;
        font-weight: 600;
    }
}

.min-container {
    width: unset;
    aspect-ratio: unset;
    background: transparent;
    backdrop-filter: unset;
    box-shadow: none;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .loader-container {
        // margin: 1rem auto;
        // aspect-ratio: unset;
        // min-height: 70vh;
    }
}

@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }