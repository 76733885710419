@mixin stroke($color: #000, $size: 1px) {
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color,
    #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color,
    0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

.mint-pass-screen {
  position: absolute;
  inset: 0 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  padding: 5rem;
  box-sizing: border-box;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .mint-pass-container {
    width: min(25rem, 90vw);
    aspect-ratio: 12 / 9;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    background: var(--glass-bg);
    border-radius: 1rem;
    backdrop-filter: var(--glass-filter);
    box-shadow: var(--glass-shadow);

    position: relative;

    .minted {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      h1 {
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
        font-weight: 800;
        text-align: center;
      }

      h2 {
        font-size: 2rem;
        font-weight: 600;
      }

      a {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        font-size: 1.2rem;
        color: var(--logo-color);
        text-decoration: none;
        font-weight: 600;

        svg {
          font-size: 1.2rem;
        }
      }
    }

    .mint-container {
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      // flex-wrap: wrap;

      padding: 1.5rem;
      box-sizing: border-box;

      .sale-type {
        font-size: 2.4rem;
        font-weight: 700;
        color: var(--logo-color);
        // @include stroke(#121809, 3px);
        font-family: var(--styled-font);
        text-align: center;
      }

      .mint-button {
        background-color: hsl(120, 90%, 57%, 0.5);
        backdrop-filter: var(--glass-filter);
        box-shadow: var(--glass-shadow);
        border: none;
        border-radius: .75rem;
        padding: 0.25rem 3rem;

        font-size: 2rem;
        font-weight: 800;
        font-family: var(--styled-font);
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: var(--logo-color);
          background-color: hsl(120, 90%, 47%, 0.65);
        }
      }
    }
  }
}

.error {
  height: 100%;
  width: 100%;

  padding: 1.5rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  h1 {
    font-size: 2.6rem;
    line-height: 3rem;

    font-weight: 800;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 800;

    text-align: center;
  }

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1.4rem;
    color: var(--logo-color);
    text-decoration: none;
    font-weight: 600;

    svg {
      font-size: 1.4rem;
    }
  }
}

.error-not-list, .mint-paused {
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: center;
}

// mobile media query
@media screen and (max-width: 768px) {
  .mint-pass-screen {
    justify-content: center;
    padding: 4rem 1rem;

    .mint-container {
      
    }
  }
}
